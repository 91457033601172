import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,{attrs:{"fill-height":"","fluid":"","grid-list-xl":""}},[_c(VLayout,{attrs:{"justify-left":"","wrap":""}},[_c(VFlex,{attrs:{"xs3":""}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" Klient-synkronisering ")]),_c(VCardText,[_c('p',[_vm._v(" Etter import av data til databanken kan klienter (applikasjoner som konsumerer Databank API) som mellomlagrer data ende opp med utdaterte lokale data. ")]),_c('p',[_vm._v(" Synkroniseringsfunksjonen starter utsending av oppdateringsmeldinger til klientene slik at mellomlagrede data blir oppdatert. ")]),_c('p',[_vm._v(" NB: Synkronisering genererer et stort antall meldinger som kan skape mye aktivitet i klient-applikasjonene. Vær sikker på at du vet hva du gjør! ")])]),_c(VCardActions,[_c(VSpacer),_c('ToolsSyncAllStores')],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }